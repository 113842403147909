import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Select, Checkbox, message, Spin } from 'antd';
import "./RepeatActions.css";

const { Option } = Select;

const RepeatActions = ({ teacherId }) => {
  const [loading, setLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [concepts, setConcepts] = useState([]);
  const [selectedConcepts, setSelectedConcepts] = useState([]);

  // Fetch schools based on teacherId
  useEffect(() => {
    if (teacherId) {
      axios.get(`/api/teachers/${teacherId}/schools`)
        .then(res => setSchools(res.data))
        .catch(err => console.error('Error fetching schools:', err));
    }
  }, [teacherId]);

  // Fetch classes when school is selected
  useEffect(() => {
    if (selectedSchool) {
      axios.get(`/api/schools/${selectedSchool}/classes`)
        .then(res => setClasses(res.data))
        .catch(err => console.error('Error fetching classes:', err));
    }
  }, [selectedSchool]);

  // Fetch sections when class is selected
  useEffect(() => {
    if (selectedClass) {
      axios.get(`/api/classes/${selectedClass}/sections`)
        .then(res => setSections(res.data))
        .catch(err => console.error('Error fetching sections:', err));
    }
  }, [selectedClass]);

  // Fetch subjects when section is selected
  useEffect(() => {
    if (selectedSection) {
      axios.get(`/api/sections/${selectedSection}/subjects`)
        .then(res => setSubjects(res.data))
        .catch(err => console.error('Error fetching subjects:', err));
    }
  }, [selectedSection]);

  // Fetch sessions when subject is selected
  useEffect(() => {
    if (selectedSubject) {
      axios.get(`/api/teachers/${teacherId}/sections/${selectedSection}/subjects/${selectedSubject}/sessions`)
        .then(res => setSessions(res.data.sessions))
        .catch(err => console.error('Error fetching sessions:', err));
    }
  }, [selectedSubject]);

  // Fetch topics and concepts when session is selected
  useEffect(() => {
    if (selectedSession) {
      axios.get(`/api/sessions/${selectedSession}/topics`)
        .then(res => {
          setTopics(res.data.topics);
          setConcepts(res.data.concepts);
        })
        .catch(err => console.error('Error fetching topics and concepts:', err));
    }
  }, [selectedSession]);

  // Handle RepeatLP Generation
  const generateRepeatLP = () => {
    if (!selectedSession || selectedTopics.length === 0 || selectedConcepts.length === 0) {
      message.error('Please select all required fields before generating.');
      return;
    }

    setLoading(true);

    const requestData = {
      sessionId: selectedSession,
      classId: selectedClass,
      sectionId: selectedSection,
      subjectId: selectedSubject,
      chapter: "Selected Chapter", // You may modify this as needed
      topics: selectedTopics.map(topic => ({
        topic,
        concepts: selectedConcepts
      }))
    };

    axios.post(`/api/repeatLPcopy/generate/${teacherId}`, requestData)
      .then(res => {
        message.success('RepeatLP Generated Successfully!');
        console.log('Generated RepeatLP:', res.data);
      })
      .catch(err => {
        console.error('Error generating RepeatLP:', err);
        message.error('Failed to generate RepeatLP.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="repeat-actions-container">
      <h2>Generate Repeat Lesson Plan</h2>

      <div className="select-group">
        <label>School:</label>
        <Select value={selectedSchool} onChange={setSelectedSchool} style={{ width: '100%' }}>
          {schools.map(school => <Option key={school.id} value={school.id}>{school.name}</Option>)}
        </Select>
      </div>

      <div className="select-group">
        <label>Class:</label>
        <Select value={selectedClass} onChange={setSelectedClass} style={{ width: '100%' }}>
          {classes.map(cls => <Option key={cls.id} value={cls.id}>{cls.className}</Option>)}
        </Select>
      </div>

      <div className="select-group">
        <label>Section:</label>
        <Select value={selectedSection} onChange={setSelectedSection} style={{ width: '100%' }}>
          {sections.map(section => <Option key={section.id} value={section.id}>{section.sectionName}</Option>)}
        </Select>
      </div>

      <div className="select-group">
        <label>Subject:</label>
        <Select value={selectedSubject} onChange={setSelectedSubject} style={{ width: '100%' }}>
          {subjects.map(subj => <Option key={subj.id} value={subj.id}>{subj.subjectName}</Option>)}
        </Select>
      </div>

      <div className="select-group">
        <label>Session:</label>
        <Select value={selectedSession} onChange={setSelectedSession} style={{ width: '100%' }}>
          {sessions.map(session => <Option key={session.sessionId} value={session.sessionId}>
            {session.chapterName} - {session.sessionNumber}
          </Option>)}
        </Select>
      </div>

      <div className="select-group">
        <label>Topics:</label>
        <Checkbox.Group options={topics.map(t => ({ label: t, value: t }))} value={selectedTopics} onChange={setSelectedTopics} />
      </div>

      <div className="select-group">
        <label>Concepts:</label>
        <Checkbox.Group options={concepts.map(c => ({ label: c, value: c }))} value={selectedConcepts} onChange={setSelectedConcepts} />
      </div>

      <Button type="primary" onClick={generateRepeatLP} loading={loading} disabled={loading} style={{ marginTop: '10px' }}>
        Generate Repeat Lesson Plan
      </Button>
    </div>
  );
};

export default RepeatActions;
