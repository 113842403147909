// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-report-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .report-details p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .report-details ul {
    list-style: none;
    padding-left: 0;
  }
  
  .report-details ul li {
    background-color: #e6f7ff;
    margin: 5px 0;
    padding: 8px;
    border-radius: 5px;
  }
  
  button {
    padding: 10px 15px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SessionReport.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":[".session-report-container {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  }\n  \n  .report-details p {\n    font-size: 16px;\n    line-height: 1.5;\n  }\n  \n  .report-details ul {\n    list-style: none;\n    padding-left: 0;\n  }\n  \n  .report-details ul li {\n    background-color: #e6f7ff;\n    margin: 5px 0;\n    padding: 8px;\n    border-radius: 5px;\n  }\n  \n  button {\n    padding: 10px 15px;\n    font-size: 14px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
