import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
import './SessionDetails.css';

const SessionDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    teacherId,
    classId,
    sectionId,
    subjectId,
    schoolId,
    sessionDetails: initialSessionDetails, // Include sessionDetails from location.state
  } = location.state || {};
  
 
  const [expandedTopic, setExpandedTopic] = useState(null);

  const [students, setStudents] = useState([]);
  const [absentees, setAbsentees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [observations, setObservations] = useState('');
  const [assignmentsEnabled, setAssignmentsEnabled] = useState(false);
  const [assignmentDetails, setAssignmentDetails] = useState('');
  const [existingFile, setExistingFile] = useState(null);
  const [file, setFile] = useState(null); // File state
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch students for attendance
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/teachers/${teacherId}/sections/${sectionId}/students`
        );
        setStudents(response.data);
      } catch (error) {
        setError('Failed to load students. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (sectionId) fetchStudents();
    else setError('Section ID is missing.');
  }, [teacherId, sectionId]);

  // Fetch session details
  // Fetch session details
useEffect(() => {
  const fetchSessionDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `/teachers/${teacherId}/sections/${sectionId}/subjects/${subjectId}/sessions`
      );
      console.log('Fetched session details:', response.data);
      setSessionDetails(response.data.sessions?.[0] || null);
    } catch (error) {
      console.error('Error fetching session details:', error);
      setError('Failed to fetch session details.');
    }
  };

  if (teacherId && sectionId && subjectId) fetchSessionDetails();
}, [teacherId, sectionId, subjectId]);

const handleConceptChange = (topicIdx) => {
  const updatedSession = { ...sessionDetails };
  updatedSession.topics[topicIdx].completed = !updatedSession.topics[topicIdx].completed;
  setSessionDetails(updatedSession);
};

  
  // Fetch assignment details
useEffect(() => {
  const fetchAssignmentDetails = async () => {
    try {
      if (sessionDetails?.sessionPlanId) {
        const response = await axiosInstance.get(`/session-assignments/${sessionDetails.sessionPlanId}`);
        
        setAssignmentDetails(response.data.assignmentDetails || '');
        
        // Handle multiple files (parse JSON if multiple files are stored)
        if (response.data.assignmentFileUrl) {
          const fileUrls = JSON.parse(response.data.assignmentFileUrl);
          setExistingFile(Array.isArray(fileUrls) ? fileUrls : [fileUrls]);
        } else {
          setExistingFile([]);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('No assignment found for this session.');
        setAssignmentDetails('');   // Reset if not found
        setExistingFile([]);        // Clear files if none exist
      } else {
        console.error('Error fetching assignment details:', error);
      }
    }
  };

  if (sessionDetails?.sessionPlanId) {
    fetchAssignmentDetails();
  }
}, [sessionDetails?.sessionPlanId]);

  const handleAbsenteeChange = (selectedOptions) => {
    const selectedIds = selectedOptions?.map((option) => option.value) || [];
    setAbsentees(selectedIds);
  };

  const handleSaveAttendance = async () => {
    const attendanceData = students.map((student) => ({
      studentId: student.id,
      date: new Date().toISOString().split('T')[0],
      status: absentees.includes(student.rollNumber) ? 'A' : 'P',
    }));

    try {
      await axiosInstance.post(
        `/schools/${schoolId}/classes/${classId}/sections/${sectionId}/attendance`,
        { attendanceData }
      );
      alert('Attendance saved successfully!');
    } catch (error) {
      alert('Failed to save attendance.');
    }
  };

  const handleAssignmentChange = (e) => {
    setAssignmentsEnabled(e.target.value === 'Yes');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Update the file state
  };

  const handleSaveAssignment = async () => {
    try {
      const formData = new FormData();
      formData.append('sessionPlanId', sessionDetails?.sessionPlanId);
      formData.append('assignmentDetails', assignmentDetails);
      
      if (file) {
        for (let i = 0; i < file.length; i++) {
          formData.append('files', file[i]); // Append multiple files
        }
      }
      
      const response = await axiosInstance.post('/session-assignments', formData);

      
      

        alert('Assignment saved successfully!');
        setSuccessMessage(response.data.message);
    } catch (error) {
        console.error('Error saving assignment:', error);
        alert('Failed to save assignment.');
    }
};

  
  

  const handleSaveObservations = async () => {
    try {
      const response = await axiosInstance.post('/observations', {
        sessionPlanId: sessionDetails?.sessionPlanId,
        observations,
    });
    
      alert('Observations saved successfully!');
    } catch (error) {
      console.error('Error saving observations:', error);
      alert('Failed to save observations.');
    }
  };

  const handleEndSession = async () => {
    if (!sessionDetails || !sessionDetails.sessionPlanId) {
      alert('Session Plan ID is missing. Cannot end the session.');
      return;
    }
  
    // Collect completed and incomplete topics based on checkbox states
    const completedTopics = [];
    const incompleteTopics = [];
  
    sessionDetails.topics.forEach((topic, idx) => {
      const isChecked = document.getElementById(`topic-${idx}`).checked;
      if (isChecked) {
        completedTopics.push(topic);
      } else {
        incompleteTopics.push(topic);
      }
    });
  
    // Ensure at least one topic is completed
    if (completedTopics.length === 0) {
      alert('Please mark at least one topic as completed.');
      return;
    }
  
    try {
      const payload = {
        sessionPlanId: sessionDetails.sessionPlanId,
        completedTopics,
        incompleteTopics,
        observations,
        absentees,
        completed: true,
      };
  
      const response = await axiosInstance.post(
        `/teachers/${teacherId}/sessions/${sessionDetails.sessionId}/end`,
        payload
      );
  
      alert(response.data.message || 'Session ended successfully!');
  
      // Redirect to the TeacherSessions page after session ends
      navigate(`/teacher-sessions/${teacherId}`); 
    } catch (error) {
      console.error('Error ending session:', error);
      alert('Failed to end the session.');
    }
  };
  
  
  
  
  
  
  
  
  
  
  
  
  const studentOptions = students.map((student) => ({
    value: student.rollNumber,
    label: student.studentName,
  }));


  return (
    <div className="session-details-container">

      {/* Welcome Message */}
      <h2>Welcome, Teacher Name!</h2>
  
      {/* Attendance Section */}
      <div className="attendance-section">
        <h3>Mark Attendance</h3>
        {loading ? (
          <p>Loading students...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : students.length === 0 ? (
          <p>No students found for this section.</p>
        ) : (
          <>
            <Select
              isMulti
              options={studentOptions}
              onChange={handleAbsenteeChange}
              placeholder="Choose Absentees"
              value={studentOptions.filter((option) => absentees.includes(option.value))}
              className="multi-select-dropdown"
              closeMenuOnSelect={false}
            />
            <button onClick={handleSaveAttendance} className="save-attendance-button">
              Save Attendance
            </button>
          </>
        )}
  
        {/* Session Notes */}
        <div className="session-notes-section">
        <h3>Session Notes and Details:</h3>
      {sessionDetails ? (
        <div className="session-item">
          <p><strong>Chapter Name:</strong> {sessionDetails.chapterName}</p>
          <h4>Topics to Cover:</h4>
          <ul>
            {sessionDetails.topics.map((topic, topicIdx) => (
              <li key={topicIdx}>
                <input
  type="checkbox"
  id={`topic-${topicIdx}`} // Add this line
  checked={topic.completed || false}
  onChange={() => handleConceptChange(topicIdx)} // Allow changes
/>

                <label>{topic.name}</label>
                <button onClick={() => setExpandedTopic(expandedTopic === topicIdx ? null : topicIdx)}>
                  {expandedTopic === topicIdx ? 'HIDE LP' : 'VIEW LP'}
                </button>
                {expandedTopic === topicIdx && (
                  <div>
                    {topic.details.map((detail, conceptIdx) => (
                      <div key={conceptIdx}>
                        <input
                          type="checkbox"
                          checked={detail.completed || false}
                          onChange={() => handleConceptChange(topicIdx, conceptIdx)}
                        />
                        <p><strong>Concept:</strong> {detail.concept}</p>
                        <p><strong>Detailing:</strong> {detail.conceptDetailing}</p>
                        {detail.lessonPlans.length > 0 && (
                          <div>
                            <p><strong>Lesson Plans:</strong></p>
                            {detail.lessonPlans.map((plan, planIdx) => (
                              <p key={planIdx}>{plan}</p>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No session details available for today.</p>
      )}




<h4>Assignments:</h4>
{assignmentDetails && (
  <div>
    <p><strong>Existing Assignment:</strong> {assignmentDetails}</p>
    {existingFile && existingFile.length > 0 && (
      <div>
        <p><strong>Uploaded Files:</strong></p>
        <ul>
          {existingFile.map((fileUrl, index) => (
            <li key={index}>
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                View File {index + 1}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
)}

<select onChange={handleAssignmentChange}>
  <option value="No">No</option>
  <option value="Yes">Yes</option>
</select>

{assignmentsEnabled && (
  <div className="assignment-input">
    <textarea
      value={assignmentDetails}
      onChange={(e) => setAssignmentDetails(e.target.value)}
      placeholder="Enter assignment details here..."
    ></textarea>

    <input
      type="file"
      onChange={(e) => setFile(e.target.files)}  // Handle multiple files
      accept=".pdf,.doc,.docx,.jpg,.png,.xls,.xlsx,.ppt,.pptx"
      multiple // Allow multiple file selection
    />

    <button onClick={handleSaveAssignment}>Save</button>
  </div>
)}


          <h4>Observations:</h4>
          <textarea
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
            className="observations-textarea"
            placeholder="Add observations of the class here..."
          ></textarea>
          <button onClick={handleSaveObservations} className="save-observations-button">
            Save Observations
          </button>
          <div className="end-session">
            <button onClick={handleEndSession} className="end-session-button">
              End Session
            </button>
          </div>

                   
        </div>
      </div>
    </div>
  );
};

export default SessionDetails;
