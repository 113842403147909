// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lesson-plans-container {
    padding: 20px;
    margin-left: 220px; /* Adjust based on sidebar width */
  }
  
  .page-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 12px;
    border: 1px solid #ddd;
  }
  
  .styled-table thead {
    background-color: #007bff;
    color: white;
  }
  
  .view-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .download-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .download-btn:hover,
  .view-btn:hover {
    opacity: 0.8;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LessonPlansPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB,EAAE,kCAAkC;EACxD;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;;IAEE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;EACjB;;EAEA;;IAEE,YAAY;EACd","sourcesContent":[".lesson-plans-container {\n    padding: 20px;\n    margin-left: 220px; /* Adjust based on sidebar width */\n  }\n  \n  .page-title {\n    text-align: center;\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  \n  .table-container {\n    overflow-x: auto;\n  }\n  \n  .styled-table {\n    width: 100%;\n    border-collapse: collapse;\n    text-align: left;\n  }\n  \n  .styled-table th,\n  .styled-table td {\n    padding: 12px;\n    border: 1px solid #ddd;\n  }\n  \n  .styled-table thead {\n    background-color: #007bff;\n    color: white;\n  }\n  \n  .view-btn {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 8px 12px;\n    cursor: pointer;\n  }\n  \n  .download-btn {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 8px 12px;\n    cursor: pointer;\n  }\n  \n  .download-btn:hover,\n  .view-btn:hover {\n    opacity: 0.8;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
