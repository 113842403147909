// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar.css */
.sidebar {
    width: 250px; /* Set the width of the sidebar */
    height: 100vh; /* Full height */
    position: fixed; /* Fix the sidebar on the left side */
    top: 0;
    left: 0;
    background-color: #333; /* Background color */
    padding-top: 20px;
  }
  
  .sidebar ul {
    list-style-type: none; /* Remove bullets */
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 15px 0; /* Add some space between items */
  }
  
  .sidebar ul li a {
    color: white; /* Link color */
    text-decoration: none; /* Remove underline */
    font-size: 18px; /* Increase font size */
    display: block;
    padding: 10px;
  }
  
  .sidebar ul li a:hover {
    background-color: #575757; /* Highlight on hover */
    padding-left: 20px; /* Indent on hover */
    transition: 0.3s;
  }
  
  /* Ensure the main content is not hidden behind the sidebar */
  .content-with-sidebar {
    margin-left: 250px; /* Same as the width of the sidebar */
    padding: 20px;
  }
  
  .content {
    padding: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,YAAY,EAAE,iCAAiC;IAC/C,aAAa,EAAE,gBAAgB;IAC/B,eAAe,EAAE,qCAAqC;IACtD,MAAM;IACN,OAAO;IACP,sBAAsB,EAAE,qBAAqB;IAC7C,iBAAiB;EACnB;;EAEA;IACE,qBAAqB,EAAE,mBAAmB;IAC1C,UAAU;EACZ;;EAEA;IACE,cAAc,EAAE,iCAAiC;EACnD;;EAEA;IACE,YAAY,EAAE,eAAe;IAC7B,qBAAqB,EAAE,qBAAqB;IAC5C,eAAe,EAAE,uBAAuB;IACxC,cAAc;IACd,aAAa;EACf;;EAEA;IACE,yBAAyB,EAAE,uBAAuB;IAClD,kBAAkB,EAAE,oBAAoB;IACxC,gBAAgB;EAClB;;EAEA,6DAA6D;EAC7D;IACE,kBAAkB,EAAE,qCAAqC;IACzD,aAAa;EACf;;EAEA;IACE,aAAa;EACf","sourcesContent":["/* Sidebar.css */\n.sidebar {\n    width: 250px; /* Set the width of the sidebar */\n    height: 100vh; /* Full height */\n    position: fixed; /* Fix the sidebar on the left side */\n    top: 0;\n    left: 0;\n    background-color: #333; /* Background color */\n    padding-top: 20px;\n  }\n  \n  .sidebar ul {\n    list-style-type: none; /* Remove bullets */\n    padding: 0;\n  }\n  \n  .sidebar ul li {\n    margin: 15px 0; /* Add some space between items */\n  }\n  \n  .sidebar ul li a {\n    color: white; /* Link color */\n    text-decoration: none; /* Remove underline */\n    font-size: 18px; /* Increase font size */\n    display: block;\n    padding: 10px;\n  }\n  \n  .sidebar ul li a:hover {\n    background-color: #575757; /* Highlight on hover */\n    padding-left: 20px; /* Indent on hover */\n    transition: 0.3s;\n  }\n  \n  /* Ensure the main content is not hidden behind the sidebar */\n  .content-with-sidebar {\n    margin-left: 250px; /* Same as the width of the sidebar */\n    padding: 20px;\n  }\n  \n  .content {\n    padding: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
