// Sidebar Update
import React from 'react'; 
import { Link, useLocation } from 'react-router-dom';
import {
  FaTachometerAlt,
  FaCalendarAlt,
  FaEnvelope,
  FaTasks,
  FaChalkboardTeacher,
  FaSignOutAlt,
  FaBook,
  FaRedo // ✅ New Icon for Repeat Lesson Plan
} from 'react-icons/fa';
import { useTeacherAuth } from '../context/TeacherAuthContext';
import './Sidebar.css';

const Sidebar = () => {
  const { teacherId, logout } = useTeacherAuth();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      console.log('Logged out successfully');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="sidebar">
      {/* Sidebar Logo */}
      <div className="sidebar-logo">
        <img src="/Upschool_2x.png" alt="UpSchool Logo" />
      </div>

      <ul>
        {/* Dashboard */}
        <li className={location.pathname === '/dashboard' ? 'active' : ''}>
          <Link to="/dashboard">
            <FaTachometerAlt className="icon" />
            <span>Dashboard</span>
          </Link>
        </li>

        {/* Teacher Sessions */}
        {teacherId && (
          <li
            className={location.pathname.includes(`/teacherportal/${teacherId}/teacher-sessions`) ? 'active' : ''}
          >
            <Link to={`/teacherportal/${teacherId}/teacher-sessions`}>
              <FaChalkboardTeacher className="icon" />
              <span>Teacher Sessions</span>
            </Link>
          </li>
        )}

        {/* School Calendar */}
        <li className={location.pathname === '/school-calendar' ? 'active' : ''}>
          <Link to="/school-calendar">
            <FaCalendarAlt className="icon" />
            <span>School Calendar</span>
          </Link>
        </li>

        {/* Request */}
        <li className={location.pathname === '/request' ? 'active' : ''}>
          <Link to="/request">
            <FaEnvelope className="icon" />
            <span>Request</span>
          </Link>
        </li>

        {/* View Activities */}
        <li className={location.pathname === '/view-activities' ? 'active' : ''}>
          <Link to="/view-activities">
            <FaTasks className="icon" />
            <span>View Activities</span>
          </Link>
        </li>

        {/* Actions & Recommendations */}
        {teacherId && (
          <li
            className={location.pathname.includes(`/teacherportal/${teacherId}/actions-recommendations`) ? 'active' : ''}
          >
            <Link to={`/teacherportal/${teacherId}/actions-recommendations`}>
              <FaTasks className="icon" />
              <span>Actions & Recommendations</span>
            </Link>
          </li>
        )}

        {/* Lesson Plans */}
        {teacherId && (
          <li
            className={location.pathname.includes(`/teacherportal/${teacherId}/lesson-plans`) ? 'active' : ''}
          >
            <Link to={`/teacherportal/${teacherId}/lesson-plans`}>
              <FaBook className="icon" />
              <span>Lesson Plans</span>
            </Link>
          </li>
        )}

        {/*  Repeat Lesson Plans (NEW) */}
        {teacherId && (
          <li
            className={location.pathname.includes(`/teacherportal/${teacherId}/repeat-lesson-plans`) ? 'active' : ''}
          >
            <Link to={`/teacherportal/${teacherId}/repeat-lesson-plans`}>
              <FaRedo className="icon" /> 
              <span>Repeat Lesson Plans</span>
            </Link>
          </li>
        )}
      

{/* Repeat LP Generation Page */}
{teacherId && (
  <li className={location.pathname.includes(`/teacherportal/${teacherId}/repeat-actions`) ? 'active' : ''}>
    <Link to={`/teacherportal/${teacherId}/repeat-actions`}>
      <FaRedo className="icon" />
      <span>Generate Repeat LP</span>
    </Link>
  </li>
)}

{/* Repeat LP Saved Plans Page */}
{teacherId && (
  <li className={location.pathname.includes(`/teacherportal/${teacherId}/repeat-lp`) ? 'active' : ''}>
    <Link to={`/teacherportal/${teacherId}/repeat-lp`}>
      <FaBook className="icon" />
      <span>Saved Repeat LP</span>
    </Link>
  </li>
)}
</ul>
      {/* Logout Button */}
      <div className="sidebar-bottom">
        <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt className="icon" />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
