import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosInstance';
import { useParams } from 'react-router-dom'; // Import useParams
import './SchoolClassTopicSelector.css';

const SchoolClassTopicSelector = () => {
  const { teacherId } = useParams(); // Extract teacherId

  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [concepts, setConcepts] = useState([]);

  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedChapter, setSelectedChapter] = useState('');
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedConcepts, setSelectedConcepts] = useState([]);

  useEffect(() => {
    const fetchAssignedSchools = async () => {
      try {
        const response = await axiosInstance.get(`/teachers/${teacherId}/assignments`);
        const assignedSchools = [...new Set(response.data.map(item => item.school))]; // Deduplicate schools
        setSchools(assignedSchools);
      } catch (error) {
        console.error('Error fetching assigned schools:', error);
      }
    };

    if (teacherId) {
      fetchAssignedSchools();
    }
  }, [teacherId]);

  const handleSchoolChange = async (schoolId) => {
    setSelectedSchool(schoolId);
    setSelectedClass('');
    setSelectedSection('');
    setSelectedSubject('');
    setSelectedChapter('');
    setSelectedTopics([]);
    setSelectedConcepts([]);

    try {
      const response = await axiosInstance.get(`/schools/${schoolId}/classes`);
      setClasses(response.data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const handleClassChange = async (classId) => {
    setSelectedClass(classId);
    setSelectedSection('');
    setSelectedSubject('');
    setSelectedChapter('');
    setSelectedTopics([]);
    setSelectedConcepts([]);

    try {
      const response = await axiosInstance.get(`/classes/${classId}/sections`);
      setSections(response.data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const handleSectionChange = async (sectionId) => {
    setSelectedSection(sectionId);
    setSelectedSubject('');
    setSelectedChapter('');
    setSelectedTopics([]);
    setSelectedConcepts([]);

    try {
      const response = await axiosInstance.get(`/sections/${sectionId}/subjects`);
      setSubjects(response.data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const handleSubjectChange = async (subjectId) => {
    setSelectedSubject(subjectId);
    setSelectedChapter('');
    setSelectedTopics([]);
    setSelectedConcepts([]);

    try {
      const response = await axiosInstance.get(`/subjects/${subjectId}/chapters`);
      setChapters(response.data);
    } catch (error) {
      console.error('Error fetching chapters:', error);
    }
  };

  const handleChapterChange = (chapter) => {
    setSelectedChapter(chapter);
    setTopics(chapter.topics);
    setSelectedTopics([]);
    setSelectedConcepts([]);
  };

  const handleTopicChange = (topicName) => {
    setSelectedTopics((prev) =>
      prev.includes(topicName)
        ? prev.filter((t) => t !== topicName)
        : [...prev, topicName]
    );
  };

  const handleConceptChange = (conceptName) => {
    setSelectedConcepts((prev) =>
      prev.includes(conceptName)
        ? prev.filter((c) => c !== conceptName)
        : [...prev, conceptName]
    );
  };

  return (
    <div className="selector-container">
      <h2>School, Class, Topic & Concept Selector</h2>

      <label>School:</label>
<select value={selectedSchool} onChange={(e) => handleSchoolChange(e.target.value)}>
  <option value="">Select School</option>
  {schools.filter(Boolean).map((school) => (
    school?.id && (
      <option key={school.id} value={school.id}>
        {school.name}
      </option>
    )
  ))}
</select>

<label>Class:</label>
<select value={selectedClass} onChange={(e) => handleClassChange(e.target.value)}>
  <option value="">Select Class</option>
  {classes.filter(Boolean).map((cls) => (
    cls?.id && (
      <option key={cls.id} value={cls.id}>
        {cls.name}
      </option>
    )
  ))}
</select>

<label>Section:</label>
<select value={selectedSection} onChange={(e) => handleSectionChange(e.target.value)}>
  <option value="">Select Section</option>
  {sections.filter(Boolean).map((sec) => (
    sec?.id && (
      <option key={sec.id} value={sec.id}>
        {sec.name}
      </option>
    )
  ))}
</select>

<label>Subject:</label>
<select value={selectedSubject} onChange={(e) => handleSubjectChange(e.target.value)}>
  <option value="">Select Subject</option>
  {subjects.filter(Boolean).map((sub) => (
    sub?.id && (
      <option key={sub.id} value={sub.id}>
        {sub.name}
      </option>
    )
  ))}
</select>

<label>Chapter:</label>
<select
  value={selectedChapter?.id || ''}
  onChange={(e) =>
    handleChapterChange(chapters.find((ch) => ch?.id === e.target.value))
  }
>
  <option value="">Select Chapter</option>
  {chapters.filter(Boolean).map((chapter) => (
    chapter?.id && (
      <option key={chapter.id} value={chapter.id}>
        {chapter.name}
      </option>
    )
  ))}
</select>


      {topics.length > 0 && (
        <div>
          <h3>Topics:</h3>
          {topics.map((topic) => (
            <div key={topic.id}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedTopics.includes(topic.name)}
                  onChange={() => handleTopicChange(topic.name)}
                />
                {topic.name}
              </label>

              {selectedTopics.includes(topic.name) && (
                <div style={{ marginLeft: '20px' }}>
                  {topic.concepts.map((concept) => (
                    <div key={concept.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedConcepts.includes(concept.name)}
                          onChange={() => handleConceptChange(concept.name)}
                        />
                        {concept.name}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SchoolClassTopicSelector;
