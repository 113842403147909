import React, { useEffect, useState, useCallback } from 'react';
import axiosInstance from '../services/axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TeacherSessions.css';
import ViewSessions from './ViewSessions';
import jsPDF from "jspdf";

const TeacherSessions = () => {
  const { teacherId } = useParams();
  const navigate = useNavigate();
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filter, setFilter] = useState({ subject: '', progress: '' , school: ''}); // Add filtering options
  
  const maxRetries = 3;
  let retryCount = 0;


  const handleDownloadSessionPlan = async (session) => {
    try {
      // Fetch the session details
      const response = await axiosInstance.get(
        `/teachers/${teacherId}/sections/${session.sectionId}/subjects/${session.subjectId}/sessions`
      );
  
      const sessionData = response.data.sessions?.[0];
  
      if (!sessionData || !sessionData.topics.length) {
        alert("No lesson plan available for this session.");
        return;
      }
  
      // Initialize jsPDF
      const doc = new jsPDF();
      doc.setFont("helvetica", "normal"); // Ensure consistent font
      doc.setFontSize(12); // Default font size
  
      const marginLeft = 10;
      const pageWidth = doc.internal.pageSize.width - 20;
      const pageHeight = doc.internal.pageSize.height - 20;
      let y = 20; // Initial Y position
  
      // Function to add headers
      const addHeader = () => {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(`Lesson Plan - ${sessionData.className} - ${sessionData.subjectName}`, marginLeft, 15);
        doc.setLineWidth(0.5);
        doc.line(marginLeft, 17, 200, 17); // Horizontal line
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        y = 25; // Reset y position for content
      };
  
      addHeader();
  
      // Prepare the lesson plan text
      let lessonPlanText = `Lesson Plan for ${sessionData.chapterName}\n\n`;
  
      sessionData.topics.forEach((topic) => {
        lessonPlanText += `Topic: ${topic.name}\n`;
        topic.details.forEach((detail) => {
          lessonPlanText += `Concept: ${detail.concept}\n`;
          lessonPlanText += `Detailing: ${detail.conceptDetailing}\n`;
  
          if (detail.lessonPlans.length > 0) {
            lessonPlanText += "Lesson Plans:\n";
            detail.lessonPlans.forEach((plan, index) => {
              lessonPlanText += `${index + 1}. ${plan}\n`;
            });
          }
          lessonPlanText += "\n"; // Add space between topics
        });
      });
  
      // Remove unnecessary markdown formatting like `**bold**`
      const cleanedText = lessonPlanText.replace(/\*\*/g, "");
  
      // Split text into lines that fit within the page width
      const lessonPlanLines = doc.splitTextToSize(cleanedText, pageWidth);
  
      // Function to check if a new page is needed
      const checkPageOverflow = (extraSpace = 10) => {
        if (y + extraSpace > pageHeight) {
          doc.addPage();
          addHeader(); // Re-add header on the new page
          doc.setFont("helvetica", "normal"); // Reset font to normal
        }
      };
  
      // Write lesson plan content to PDF
      lessonPlanLines.forEach((line) => {
        checkPageOverflow(); // Ensure no text overlaps
        doc.text(line, marginLeft, y);
        y += 6; // Move cursor down for next line
      });
  
      // Save the PDF
      doc.save(`${sessionData.chapterName}_LessonPlan.pdf`);
    } catch (error) {
      console.error("Error fetching session plan:", error);
      alert("Failed to download lesson plan.");
    }
  };
  

  
  const fetchSessionPlansForToday = async (teacherId, sectionId, subjectId) => {
    try {
      const response = await axiosInstance.get(
        `/teachers/${teacherId}/sections/${sectionId}/subjects/${subjectId}/sessions`
      );
  
      const todayDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
  
      // Filter sessions for today
      const sessionsForToday = response.data.sessions.filter((session) => {
        const sessionDate = new Date(session.sessionDate).toISOString().split('T')[0];
        return sessionDate === todayDate;
      });
  
      return sessionsForToday.length > 0 ? sessionsForToday : [];
    } catch (error) {
      console.error('Error fetching session plans for today:', error);
      return [];
    }
  };
  
  //viewing session plans
  const handleViewPlan = (sessionPlans) => {
    if (!sessionPlans || sessionPlans.length === 0) {
      alert('No session plan available for this session.');
      return;
    }
  
    const hardcodedDetails = {
      topicName: "Concept of Charge",
      learningObjectives: [
        "Understand the concept and definition of charge.",
        "Identify the two types of charges: positive and negative.",
        "Explain the interactions between charges (attraction and repulsion).",
        "Understand the concept of neutrality in atoms."
      ],
      lessonPlanFlow: [
        {
          step: "Introduction (3 minutes)",
          details: [
            "Teacher’s Introduction: 'Everything around us is made up of matter, and one of the fundamental properties of matter is charge. Today, we’ll explore what charge is and its significance.'",
            "Engage Students: 'Have you ever noticed how your hair sometimes sticks to a comb after brushing? Or how a balloon can stick to a wall after being rubbed on your clothes? These are everyday examples of charges at work.'"
          ]
        },
        {
          step: "Explanation (10 minutes)",
          details: [
            "Concept 01: Definition of Charge - Charge is a fundamental property of matter that causes it to experience a force when placed in an electric or magnetic field.",
            "Concept 02: Types of Charges - Positive charge is associated with protons (+), and negative charge is associated with electrons (-).",
            "Concept 03: Nature of Interaction - Like charges repel; opposite charges attract.",
            "Concept 04: Neutrality in Atoms - Atoms are neutral when the number of protons equals the number of electrons."
          ]
        },
        {
          step: "Recap and Clarifications (2 minutes)",
          details: [
            "Quick Recap Questions: What is charge? Name the two types of charges. What happens when like charges come close to each other? Why are atoms neutral?",
            "Recap Points: Charge is a property of matter. Types: Positive (proton) and Negative (electron). Like charges repel; opposite charges attract. Atoms are neutral because protons equal electrons."
          ]
        }
      ]
    };
  
    navigate('/view-sessions', { state: { sessionPlans, hardcodedDetails } });
  };
  
  
  
  // Utility function to get the day name
  const getDayName = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  };

  // Fetch sessions assigned to the teacher along with session plans
  const fetchSessions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/teachers/${teacherId}/assignments`);
  
      const sessionsWithPlans = await Promise.all(
        response.data.map(async (session) => {
          const sessionPlansForToday = await fetchSessionPlansForToday(
            teacherId,
            session.sectionId,
            session.subjectId
          );
          return { ...session, sessionPlansForToday };
        })
      );
  
      setSessions(sessionsWithPlans);
      setError(null);
    } catch (err) {
      setError(`Failed to load sessions: ${err.message}. Please try again later.`);
    } finally {
      setLoading(false);
    }
  }, [teacherId]);
  
  
  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  // Filter sessions based on selected day and additional filters
  useEffect(() => {
    const day = getDayName(selectedDate);
    let filtered = sessions.filter((session) => session.day === day);

    // Apply subject and progress filters
    if (filter.school) {
      filtered = filtered.filter((session) => session.schoolName === filter.school);
    }
  
    if (filter.subject) {
      filtered = filtered.filter((session) => session.subjectName === filter.subject);
    }
    if (filter.progress === 'completed') {
      filtered = filtered.filter((session) => session.completedTopics === session.totalTopics);
    }
    if (filter.progress === 'incomplete') {
      filtered = filtered.filter((session) => session.completedTopics < session.totalTopics);
    }

    setFilteredSessions(filtered);
  }, [selectedDate, sessions, filter]);

  // Handle date change for filtering sessions
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Handle start session button click
  const handleStartSession = (session) => {
    if (!session.sectionId) {
      console.error('Section ID is undefined for the session:', session);
      alert('Unable to start session: Section ID is missing.');
      return;
    }
  
    navigate(`/teacherportal/${teacherId}/session-details`, {
      state: {
        teacherId,
        classId: session.classId, // Pass classId
        sectionId: session.sectionId,
        subjectId: session.subjectId, // Pass subjectId
        schoolId: session.schoolId, // Pass schoolId
        day: session.day,
        period: session.period,
      },
    });
  };
  
  
  
  const isToday = (date) => date.toDateString() === new Date().toDateString();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="sessions-container">
      <h2>Teacher Sessions - {getDayName(selectedDate)}'s Sessions ({selectedDate.toDateString()})</h2>

      <div className="navigation-buttons">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          maxDate={new Date()}
          dateFormat="yyyy-MM-dd"
        />

        {/* Filters for school, subject and progress */}
        <select onChange={(e) => setFilter({ ...filter, school: e.target.value })}>
          <option value="">All Schools</option>
          {Array.from(new Set(sessions.map((session) => session.schoolName))).map((school) => (
            <option key={school} value={school}>
              {school}
            </option>
          ))}
        </select>

        <select onChange={(e) => setFilter({ ...filter, subject: e.target.value })}>
          <option value="">All Subjects</option>
          {Array.from(new Set(sessions.map((session) => session.subjectName))).map((subject) => (
            <option key={subject} value={subject}>
              {subject}
            </option>
          ))}
        </select>

        <select onChange={(e) => setFilter({ ...filter, progress: e.target.value })}>
          <option value="">All Progress</option>
          <option value="completed">Completed</option>
          <option value="incomplete">Incomplete</option>
        </select>
      </div>

      {filteredSessions.length === 0 ? (
  <p className="no-sessions-message">No sessions found for {getDayName(selectedDate)}.</p>
) : (
  <table className="sessions-table">
    <thead>
      <tr>
        <th>School</th>
        <th>Class</th>
        <th>Section</th>
        <th>Day</th>
        <th>Period</th>
        <th>Subject</th>
        <th>Start Time</th>
        <th>End Time</th>
        <th>Download Session Plan</th>  {/* New Column */}

      </tr>
    </thead>
    <tbody>
  {filteredSessions.map((session, index) => {
    const progressPercentage =
      session.totalTopics > 0 ? (session.completedTopics / session.totalTopics) * 100 : 0;

    return (
      <tr key={index}>
        <td>{session.schoolName}</td>
        <td>{session.className}</td>
        <td>{session.sectionName}</td>
        <td>{session.day}</td>
        <td>{session.period}</td>
        <td>{session.subjectName}</td>
                <td>
          {session.completed ? (
            <span>{new Date(session.actualStartTime).toLocaleTimeString() || '-'}</span>
          ) : isToday(selectedDate) ? (
            <button
              onClick={() => handleStartSession(session)}
              style={{ backgroundColor: '#dc3545', color: 'white' }}
            >
              Start Session
            </button>
          ) : (
            <span>-</span>
          )}
        </td>
        <td>{session.endTime}</td>
        <td>
  <button onClick={() => handleDownloadSessionPlan(session)}>
    Download PDF
  </button>
</td>

      </tr>
    );
  })}
</tbody>

  </table>
)}

    </div>
  );
};

export default TeacherSessions;
