import React, { useEffect, useState } from "react";
import axiosInstance from "../services/axiosInstance";
import { useLocation } from "react-router-dom";
import "./LessonPlansPage.css";
import jsPDF from "jspdf";

const LessonPlansPage = () => {
  const location = useLocation();
  const [lessonPlans, setLessonPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(" Location State:", location.state);

    if (location.state?.lessonPlanData && location.state.lessonPlanData.length > 0) {
      console.log(" Using lesson plan data from navigation state");
      setLessonPlans(location.state.lessonPlanData);
    } else {
      console.log(" Fetching lesson plans from backend...");
      fetchLessonPlans();
    }
  }, [location.state]);

  const fetchLessonPlans = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/repeatedLessonPlans");
      console.log(" Lesson Plans Retrieved:", response.data);

      if (!Array.isArray(response.data) || response.data.length === 0) {
        console.warn(" No lesson plans retrieved, setting hardcoded default data.");
        setLessonPlans([
          {
            schoolName: "Upschool Ed Tech 1",
            board: "CBSE",
            className: "8",
            sectionName: "A",
            subjectName: "Science",
            topicName: "Understanding Poverty",
            conceptName: "Poverty Line",
            chapterName: "Current Electricity",
            unitName: "Electricity",
            generatedLP: "Lesson Plan Content Here..."
          }
        ]);
      } else {
        setLessonPlans(response.data);
      }
    } catch (error) {
      console.error(" Error fetching lesson plans:", error);
      // Set hardcoded data in case of API failure
      setLessonPlans([
        {
          schoolName: "Upschool Ed Tech 1",
          board: "CBSE",
          className: "8",
          sectionName: "A",
          subjectName: "Science",
          topicName: "Understanding Poverty",
          conceptName: "Poverty Line",
          chapterName: "Current Electricity",
          unitName: "Electricity",
          generatedLP: "Lesson Plan Content Here..."
        }
      ]);
    }
    setLoading(false);
  };

  const handleDownloadLessonPlan = (plan) => {
    if (!plan || !plan.generatedLP) {
      console.error("Invalid lesson plan data.");
      alert("Error: Lesson Plan data is missing!");
      return;
    }

    const doc = new jsPDF();
    const lineHeight = 6;
    const pageHeight = doc.internal.pageSize.height - 20;
    let y = 20;

    const addHeader = () => {
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text(`Class: ${plan.className} - ${plan.subjectName} Lesson Plan`, 10, 10);
      doc.line(10, 12, 200, 12);
    };

    addHeader();

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    const cleanedText = plan.generatedLP.replace(/\*\*/g, "");
    const lessonPlanLines = doc.splitTextToSize(cleanedText, 180);

    lessonPlanLines.forEach((line) => {
      if (y + lineHeight > pageHeight) {
        doc.addPage();
        y = 10;
      }
      doc.text(line, 10, y);
      y += lineHeight;
    });

    doc.save(`${plan.topicName}_LessonPlan.pdf`);
  };

  return (
    <div className="lesson-plans-container">
      <h2 className="page-title">Generated Lesson Plans</h2>

      {loading ? (
        <p>Loading lesson plans...</p>
      ) : lessonPlans.length > 0 ? (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>School</th>
                <th>Board</th>
                <th>Class</th>
                <th>Section</th>
                <th>Subject</th>
                <th>Unit</th>
                <th>Chapter</th>
                <th>Topic</th>
                <th>Concept</th>
                <th>Lesson Plan</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {lessonPlans.map((plan, index) => (
                <tr key={index}>
                  <td>{plan.schoolName || "Upschool Ed Tech 1"}</td>
                  <td>{"CBSE"}</td> {/* Hardcoded Board */}
                  <td>{plan.className || "8"}</td>
                  <td>{"B"}</td> {/* Hardcoded Section */}
                  <td>{"Physics"}</td>
                  <td>{"Electricity"}</td> {/* Hardcoded Unit */}
                  <td>{"Current Electricity"}</td> {/* Hardcoded Chapter */}
                  <td>{plan.topicName || "Understanding Poverty"}</td>
                  <td>{plan.conceptName || "Poverty Line"}</td>

                  <td>
                    <button className="view-btn" onClick={() => alert(plan.generatedLP)}>View</button>
                  </td>
                  <td>
                    <button className="download-btn" onClick={() => handleDownloadLessonPlan(plan)}>Download PDF</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No lesson plans available.</p>
      )}
    </div>
  );
};

export default LessonPlansPage;
