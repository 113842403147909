import React, { useEffect, useState } from "react";
import axiosInstance from "../services/axiosInstance";
import { useLocation } from "react-router-dom";
import { jsPDF } from 'jspdf';
import "./RepeatLP.css";

const RepeatLP = () => {
  const location = useLocation();
  const [repeatLessonPlans, setRepeatLessonPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(" Location State:", location.state);

    if (location.state?.repeatLessonPlanData) {
      console.log(" Using repeat lesson plan data from navigation state");
      setRepeatLessonPlans(location.state.repeatLessonPlanData || []);
    } else {
      console.log(" Fetching repeat lesson plans from backend...");
      fetchRepeatLessonPlans();
    }
  }, [location.state]);

  const fetchRepeatLessonPlans = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/repeatLessonPlans");
      console.log(" Repeat Lesson Plans Retrieved:", response.data);

      if (!Array.isArray(response.data)) {
        console.error(" Unexpected API Response:", response.data);
        return;
      }

      setRepeatLessonPlans(response.data);
    } catch (error) {
      console.error(" Error fetching repeat lesson plans:", error);
    }
    setLoading(false);
  };

  const handleDownloadRepeatLP = (plan) => {
    if (!plan || !plan.generatedLP) {
      console.error(" Invalid repeat lesson plan data.");
      alert("Error: Repeat Lesson Plan data is missing!");
      return;
    }

    const doc = new jsPDF();
    const lineHeight = 6;
    const pageHeight = doc.internal.pageSize.height - 20;
    let y = 20;

    //  Add header with school, board, class, and subject details
    const addHeader = () => {
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text(
        `Class: ${plan.className || "N/A"} - ${plan.subjectName || "N/A"} Repeat Lesson Plan`,
        10,
        10
      );
      doc.line(10, 12, 200, 12);
    };

    addHeader();

    //  Ensure key information appears at the start
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`School: ${plan.schoolName || "N/A"}`, 10, y);
    doc.text(`Board: ${plan.boardName || "N/A"}`, 10, y + lineHeight);
    y += lineHeight * 2;

    //  Format and add lesson plan content
    const repeatLessonPlan = plan.generatedLP
      .replace(/\*\*/g, "") // Remove all ** symbols
      .split("\n");

    repeatLessonPlan.forEach((line) => {
      if (y > pageHeight) {
        doc.addPage();
        y = 10;
      }
      doc.text(line, 10, y);
      y += lineHeight;
    });

    doc.save(`${plan.topicName}_RepeatLessonPlan.pdf`);
  };

  return (
    <div className="repeat-lp-container">
      <h2 className="page-title">Generated Repeat Lesson Plans</h2>

      {loading ? (
        <p>Loading repeat lesson plans...</p>
      ) : repeatLessonPlans.length > 0 ? (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>School</th>
                <th>Board</th>
                <th>Class</th>
                <th>Section</th>
                <th>Subject</th>
                <th>Topic</th>
                <th>Concepts</th>
                <th>Lesson Plan</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {repeatLessonPlans.map((plan, index) => (
                <tr key={index}>
                  <td>{plan.schoolName || "N/A"}</td>
                  <td>{plan.boardName || "N/A"}</td>
                  <td>{plan.className || "N/A"}</td>
                  <td>{plan.sectionName || "N/A"}</td>
                  <td>{plan.subjectName || "N/A"}</td>
                  <td>{plan.topicName}</td>
                  <td>{plan.concepts?.join(", ") || "N/A"}</td>
                  <td>
                    <button className="view-btn" onClick={() => alert(plan.generatedLP)}>
                      View
                    </button>
                  </td>
                  <td>
                    <button className="download-btn" onClick={() => handleDownloadRepeatLP(plan)}>
                      Download PDF
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No repeat lesson plans available.</p>
      )}
    </div>
  );
};

export default RepeatLP;
