// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../root/.nvm/versions/node/v20.12.2/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.repeat-actions-container {
    margin-left: 250px; /* Adjust according to your sidebar width */
    padding: 20px;
    width: calc(100% - 250px);
    box-sizing: border-box;
}

.select-group {
    margin-bottom: 15px;
}

.select-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.select-group .ant-select {
    width: 100%;
}

.select-group .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.ant-btn {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/RepeatActions.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,2CAA2C;IAC/D,aAAa;IACb,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,cAAc;IACd,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".repeat-actions-container {\n    margin-left: 250px; /* Adjust according to your sidebar width */\n    padding: 20px;\n    width: calc(100% - 250px);\n    box-sizing: border-box;\n}\n\n.select-group {\n    margin-bottom: 15px;\n}\n\n.select-group label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\n.select-group .ant-select {\n    width: 100%;\n}\n\n.select-group .ant-checkbox-group {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.ant-btn {\n    display: block;\n    width: 100%;\n    font-size: 16px;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
