import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import './ActionsnR.css';

const ActionsnR = () => {
  const { teacherId } = useParams();
  const navigate = useNavigate(); // Hook to navigate to another page

  const [sessionDetails, setSessionDetails] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedConcepts, setSelectedConcepts] = useState([]);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [boards] = useState(["CBSE", "ICSE", "State Board"]); // Hardcoded board options
  const [sessionType] = useState("Reteach"); // Hardcoded as "Reteach"
  const [duration] = useState(45); // Default duration
  const [selectedBoard, setSelectedBoard] = useState('');
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const response = await axiosInstance.get(`/teachers/${teacherId}/assignments`);
        const data = response.data;

        setSchools([...new Set(data.map(item => item.schoolName))]);
        setClasses([...new Set(data.map(item => item.className))]);
        setSections([
          ...new Map(data.map(item => [item.sectionId, { id: item.sectionId, name: item.sectionName }])).values()
        ]);
        setSubjects([
          ...new Map(data.map(item => [item.subjectId, { id: item.subjectId, name: item.subjectName }])).values()
        ]);
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
    };

    fetchAssignments();
  }, [teacherId]);

  const handleFetchDetails = async () => {
    if (!selectedSection || !selectedSubject) {
      alert('Please select both Section and Subject.');
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/teachers/${teacherId}/sections/${selectedSection}/subjects/${selectedSubject}/sessions`
      );

      const sessions = response.data.sessions || [];
      setSessionDetails(sessions);
      setShowDetails(true);

      if (sessions.length > 0) {
        setSelectedChapter(sessions[0]); // Automatically select the first available chapter
      }
    } catch (error) {
      console.error('Error fetching session details:', error);
      alert('Error fetching session details.');
    }
  };

  const handleTopicChange = (topicName) => {
    setSelectedTopics((prev) =>
      prev.includes(topicName) ? prev.filter((t) => t !== topicName) : [...prev, topicName]
    );
  };

  const handleConceptChange = (conceptName) => {
    setSelectedConcepts((prev) =>
      prev.includes(conceptName) ? prev.filter((c) => c !== conceptName) : [...prev, conceptName]
    );
  };

  const handleGenerateLP = async () => {
    if (!selectedBoard || !selectedSchool || !selectedClass || !selectedSection || !selectedSubject || selectedTopics.length === 0) {
        alert("Please select all required fields before generating the lesson plan.");
        return;
    }

    // Fetch chapter name dynamically if available
    const selectedChapter = sessionDetails.length > 0 ? sessionDetails[0].chapterName : "Current Electricity";

    // Create payload in the **EXACT SAME FORMAT** as the working cURL request
    const payload = {
        board: selectedBoard,
        school: selectedSchool,
        grade: selectedClass,
        section: selectedSection,
        subject: selectedSubject,
        unit: "Electricity",
        chapter: selectedChapter,
        sessionType: "Reteach",
        noOfSession: selectedTopics.length,
        duration: 45,
        topics: selectedTopics.map(topic => {
            // Find matching topic from sessionDetails
            const topicData = sessionDetails
                .flatMap(s => s.topics)
                .find(t => t.name === topic);

            return {
                topic: topic,
                concepts: topicData ? topicData.details.map(d => d.concept) : [],
                conceptDetails: topicData ? topicData.details.map(d => d.conceptDetailing) : [],
                conceptIds: topicData ? topicData.details.map(d => d.conceptId).filter(id => id !== null) : [] // ✅ Fixes null conceptIds
            };
        })
    };

    console.log(" Sending Payload to API:", JSON.stringify(payload, null, 2));

    try {
        // Make the API call using Axios
        const response = await axiosInstance.post('/repeatLP/generate', payload);
        console.log(" API Response:", response.data);

if (!response.data.reteach_plan) {
    alert("Done!");
    return;
}

navigate(`/teacherportal/${teacherId}/lesson-plans`, { 
  state: { 
      lessonPlanData: response.data.reteach_plan,
      selectedBoard,
      selectedSchool,
      selectedClass,
      selectedSection,
      selectedSubject,
      selectedChapter,
      selectedUnit: "Electricity" // Hardcoded, update dynamically if needed
  } 
});

    } catch (error) {
        console.error(" Error generating lesson plan:", error);
        alert("Failed to generate lesson plan.");
    }
};

  

  return (
    <div className="actions-form-container">
      <h2>Recommendations</h2>

      <form>
        <label>Board:</label>
        <select value={selectedBoard} onChange={(e) => setSelectedBoard(e.target.value)}>
          <option value="">Select Board</option>
          {boards.map((board, index) => (
            <option key={index} value={board}>{board}</option>
          ))}
        </select>

        <label>School:</label>
        <select value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)}>
          <option value="">Select School</option>
          {schools.map((school, index) => (
            <option key={index} value={school}>{school}</option>
          ))}
        </select>

        <label>Class:</label>
        <select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
          <option value="">Select Class</option>
          {classes.map((cls, index) => (
            <option key={index} value={cls}>{cls}</option>
          ))}
        </select>

        <label>Section:</label>
        <select value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)}>
          <option value="">Select Section</option>
          {sections.map((sec, index) => (
            <option key={index} value={sec.id}>{sec.name}</option>
          ))}
        </select>

        <label>Subject:</label>
        <select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
          <option value="">Select Subject</option>
          {subjects.map((sub, index) => (
            <option key={index} value={sub.id}>{sub.name}</option>
          ))}
        </select>

        <button type="button" onClick={handleFetchDetails}>Show Details</button>
      </form>

      {showDetails && selectedChapter && (
        <div>
          <h3>Topics:</h3>
          {selectedChapter.topics.map((topic, index) => (
            <div key={index}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedTopics.includes(topic.name)}
                  onChange={() => handleTopicChange(topic.name)}
                />
                {topic.name}
              </label>
              {selectedTopics.includes(topic.name) && (
                <div style={{ marginLeft: '20px' }}>
                  {topic.details.map((concept, idx) => (
                    <div key={idx}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedConcepts.includes(concept.concept)}
                          onChange={() => handleConceptChange(concept.concept)}
                        />
                        {concept.concept}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          <button type="button" onClick={handleGenerateLP}>Generate and View Lesson Plan</button>
        </div>
      )}
    </div>
  );
};

export default ActionsnR;
