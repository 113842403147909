// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .session-table th, .session-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .session-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  .session-container {
    padding: 20px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .date-picker {
    margin-bottom: 20px;
  }
  
  .session-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .session-table th, .session-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .start-button, .end-button, .action-button {
    padding: 5px 10px;
    margin: 2px;
    cursor: pointer;
  }
  
  .start-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .end-button {
    background-color: #f44336;
    color: white;
  }
  
  .action-button {
    background-color: #008CBA;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Session.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;EAClB;EACA;IACE,aAAa;EACf;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".session-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .session-table th, .session-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .session-table th {\n    background-color: #f2f2f2;\n    text-align: left;\n  }\n  .session-container {\n    padding: 20px;\n  }\n  \n  .error-message {\n    color: red;\n    margin-top: 10px;\n  }\n  \n  .date-picker {\n    margin-bottom: 20px;\n  }\n  \n  .session-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .session-table th, .session-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n  }\n  \n  .start-button, .end-button, .action-button {\n    padding: 5px 10px;\n    margin: 2px;\n    cursor: pointer;\n  }\n  \n  .start-button {\n    background-color: #4CAF50;\n    color: white;\n  }\n  \n  .end-button {\n    background-color: #f44336;\n    color: white;\n  }\n  \n  .action-button {\n    background-color: #008CBA;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
